.MuiChip-label {
    padding: 0 !important;
}
.MuiPickersToolbar-toolbar {
    background-color: #2663FF !important
}
.MuiPickersDay-daySelected {
    background-color: #2663FF !important
}
.MuiBackdrop-root {
    top: 64px !important;
}