/* html {
  margin-left: calc(100vw - 100%);
} */

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

p {
  color: #434c5f;
}

.no-exists {
  display: none;
}
.main-theme-color {
  color: #3577d4 !important;
}
.main-theme-text {
  color: #434c5f !important;
}
.red-text {
  color: #b00020 !important;
}
.full-width {
  width: 100%;
}
.half-width {
  width: 50%;
}

.spacing-less {
  padding: 0rem 0rem !important;
  margin: 0px !important;
}

.strong {
  font-weight: bold !important;
}
.bg-white {
  background-color: #ffffff;
}
.font-14 {
  font-size: 14px;
}
.font-32 {
  font-size: 32px;
}
.margin-left {
  margin-left: 5px;
}

.width-5 {
  width: 6%;
}

.middle {
  vertical-align: middle !important;
}
.top-10 {
  padding-top: 10px !important;
}
.right-10 {
  padding-right: 10px !important;
}
.width-20 {
  width: 20%;
}
.socials {
  padding-left: 5px;
}
.footer-side-right {
  min-width: 20%;
}
.full-height {
  height: 100%;
}
.vertical-bottom {
  min-height: 100%;
}
.footer-nav {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
}
.footer-nav-fix {
  height: 88px;
}
.footer-cover {
  min-height: 90px;
}
.swagup-big-banner {
  width: 172px;
  height: 40px;
}

.empty-section-svg {
  width: 180px;
  height: 180px;
}

.submit-button {
  background-color: #3577d4 !important;
  color: #ffffff !important;
  text-decoration-line: none !important;
  width: 80%;
  padding-top: 14px !important;
  padding-bottom: 15px !important;
}

.scroll-menu-arrow--disabled {
  display: none;
}

.link-animated-bottom {
  animation-direction: reverse;
}

.animated-link:hover > .link-animated-bottom {
  opacity: 1 !important;
  -webkit-animation-name: go-big;
  -webkit-animation-duration: 0.2s;
  animation-name: go-big;
  animation-duration: 0.3s;
  transition-timing-function: ease-out;
}

@keyframes go-big {
  from {
    width: 100%;
    opacity: 0.5;
  }
  to {
    width: 0px;
    opacity: 0;
  }
}

@keyframes go-short {
  from {
    width: 0px;
  }
  to {
    width: 100%;
  }
}

/* Header links animation*/
.submit-button:hover {
  background-color: #2466c3 !important;
  color: #ffffff !important;
  font-weight: bold;
}

/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

/* Fading animation */
.fade-translate {
  -webkit-animation-name: translate;
  -webkit-animation-duration: 0.5s;
  animation-name: translate;
  animation-duration: 0.5s;
}

@keyframes translate {
  0% {
    opacity: 0;
    transform: translate(10%, 0);
  }

  100% {
    opacity: 1;
    transform: translate(0%, 0);
  }
}

/* Fade&translate 4 vertivcalSlider*/
/* Fading animation In*/
.fade-translate-vts-in {
  -webkit-animation-name: translationIn;
  -webkit-animation-duration: 1s;
  animation-name: translationIn;
  animation-duration: 1s;
}

@keyframes translationIn {
  0% {
    opacity: 0;
    transform: translate(0, -10%);
  }

  100% {
    opacity: 1;
    transform: translate(0, 0%);
  }
}
/* Fading animation Out*/
.fade-translate-vts-out {
  -webkit-animation-name: translationOut;
  -webkit-animation-duration: 2s;
  animation-name: translationOut;
  animation-duration: 2s;
  transition-timing-function: ease-out;
}

.animated-link-bottom a {
  text-decoration: none !important;
}

.animated-link-bottom a:hover {
  text-decoration: none !important;
  font-weight: bold;
}
.animated-link-bottom button:hover {
  text-decoration: none !important;
  font-weight: bold;
}

@keyframes translationOut {
  70% {
    opacity: 1;
    transform: translate(0, 0%);
  }

  100% {
    opacity: 0;
    transform: translate(0, 10%);
  }
}

@media (max-width: 767px) {
  .swagup-big-banner {
    width: 92px;
    height: 24px;
  }
  .logoBanner {
    width: 80px;
    height: 18px;
  }

  .swagupBanner {
    width: 70px;
    height: 18px;
  }

  .mobile-no {
    visibility: collapse !important;
    width: 0px !important;
  }
  .mobile-expand {
    width: 100%;
  }
  .mobile-easy-margin {
    margin: 0px 5% !important;
  }
  .mobile-socials {
    width: 15px;
    height: 15px;
    padding-left: 2px;
    margin-top: 10px;
  }
}

.slider-thumb {
  animation: animationFrames linear 2s;
  animation-iteration-count: 1;
  transform-origin: 50% 100%;
}

@keyframes animationFrames {
  0% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(-15deg);
  }
  60% {
    transform: rotate(5deg);
  }
  80% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@-moz-keyframes animationFrames {
  0% {
    -moz-transform: rotate(0deg);
  }
  20% {
    -moz-transform: rotate(-15deg);
  }
  60% {
    -moz-transform: rotate(5deg);
  }
  80% {
    -moz-transform: rotate(-5deg);
  }
  100% {
    -moz-transform: rotate(0deg);
  }
}

@-webkit-keyframes animationFrames {
  0% {
    -webkit-transform: rotate(0deg);
  }
  20% {
    -webkit-transform: rotate(-15deg);
  }
  60% {
    -webkit-transform: rotate(5deg);
  }
  Z {
    -webkit-transform: rotate(-5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}

@-o-keyframes animationFrames {
  0% {
    -o-transform: rotate(0deg);
  }
  20% {
    -o-transform: rotate(-15deg);
  }
  60% {
    -o-transform: rotate(5deg);
  }
  90% {
    -o-transform: rotate(-5deg);
  }
  100% {
    -o-transform: rotate(0deg);
  }
}

@-ms-keyframes animationFrames {
  0% {
    -ms-transform: rotate(0deg);
  }
  20% {
    -ms-transform: rotate(-15deg);
  }
  60% {
    -ms-transform: rotate(5deg);
  }
  90% {
    -ms-transform: rotate(-5deg);
  }
  100% {
    -ms-transform: rotate(0deg);
  }
}

.slider-thumb-inverse {
  animation: animationFramesInverse linear 2s;
  animation-iteration-count: 1;
  transform-origin: 50% 100%;
}

@keyframes animationFramesInverse {
  0% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(15deg);
  }
  60% {
    transform: rotate(-5deg);
  }
  90% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@-moz-keyframes animationFramesInverse {
  0% {
    -moz-transform: rotate(0deg);
  }
  20% {
    -moz-transform: rotate(15deg);
  }
  60% {
    -moz-transform: rotate(-5deg);
  }
  90% {
    -moz-transform: rotate(5deg);
  }
  100% {
    -moz-transform: rotate(0deg);
  }
}

@-webkit-keyframes animationFramesInverse {
  0% {
    -webkit-transform: rotate(0deg);
  }
  20% {
    -webkit-transform: rotate(15deg);
  }
  60% {
    -webkit-transform: rotate(-5deg);
  }
  90% {
    -webkit-transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}

@-o-keyframes animationFramesInverse {
  0% {
    -o-transform: rotate(0deg);
  }
  20% {
    -o-transform: rotate(15deg);
  }
  60% {
    -o-transform: rotate(-5deg);
  }
  80% {
    -o-transform: rotate(5deg);
  }
  100% {
    -o-transform: rotate(0deg);
  }
}

@-ms-keyframes animationFramesInverse {
  0% {
    -ms-transform: rotate(0deg);
  }
  20% {
    -ms-transform: rotate(15deg);
  }
  60% {
    -ms-transform: rotate(-5deg);
  }
  80% {
    -ms-transform: rotate(5deg);
  }
  100% {
    -ms-transform: rotate(0deg);
  }
}

input {
  -webkit-appearance: none;
}

iframe:not([id]) {
  display: none;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
