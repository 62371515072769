
@font-face {
  font-family: 'FuturaLTPro';
  src: url('webfonts/3842D3_0_0.eot');
  src: url('webfonts/3842D3_0_0.eot?#iefix') format('embedded-opentype'),
    url('webfonts/3842D3_0_0.woff2') format('woff2'), url('webfonts/3842D3_0_0.woff') format('woff'),
    url('webfonts/3842D3_0_0.ttf') format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'Futura';
  src: url('webfonts/3842F8_0_0.eot');
  src: url('webfonts/3842F8_0_0.eot?#iefix') format('embedded-opentype'),
    url('webfonts/3842F8_0_0.woff2') format('woff2'), url('webfonts/3842F8_0_0.woff') format('woff'),
    url('webfonts/3842F8_0_0.ttf') format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'Futura-Medium';
  src: url('webfonts/Futura-Medium.ttf') format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'FuturaBT-Book';
  src: url('webfonts/Futura-Book-BT.ttf') format('truetype');
  font-display: swap;
}
/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: 'Gilroy-Bold';
  font-style: normal;
  font-weight: normal;
  src: url('webfonts/Gilroy-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy-Heavy';
  font-style: normal;
  font-weight: normal;
  src: url('webfonts/Gilroy-Heavy.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy-Light';
  font-style: normal;
  font-weight: normal;
  src: url('webfonts/Gilroy-Light.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy-Medium';
  font-style: normal;
  font-weight: normal;
  src: url('webfonts/Gilroy-Medium.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy-Regular';
  font-style: normal;
  font-weight: normal;
  src: url('webfonts/Gilroy-Regular.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 600;
  src: url('webfonts/Gilroy-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'AvenirNext';
  font-style: normal;
  font-weight: 500;
  src: url('webfonts/AvenirNext-Medium.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Gilroy-SemiBold';
  font-style: normal;
  font-weight: 500;
  src: url('webfonts/RadomirTinkov-Gilroy-SemiBold.otf') format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Roboto-Medium';
  src: url('webfonts/Roboto-Medium.ttf') format('truetype');
  font-display: swap;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  overflow-y: scroll;
}

p {
  margin: 0;
}

a {
  color: #3577d4;
  text-decoration-line: none;
}

a:hover {
  text-decoration-line: none !important;
}

.os-scrollbar-vertical {
  width: 10px !important;
}

.os-scrollbar-track {
  background-color: #EBEEF2 !important;
}

.os-scrollbar-handle {
  background-color: #D3DBE5 !important;
}

/* override react-scrollbars-custom default styles */
.ScrollbarsCustom-Track {
  background: #ebeef2 !important;
}

.ScrollbarsCustom-Track.ScrollbarsCustom-TrackY {
  width: 4px !important;
  height: calc(100% - 10px) !important;
  top: 0 !important;
  right: 5px !important;
}

.ScrollbarsCustom-Track.ScrollbarsCustom-TrackX {
  width: calc(100% - 10px) !important;
  height: 4px !important;
  left: 0 !important;
  bottom: 5px !important;
}

.ScrollbarsCustom-Thumb {
  background: #d3dbe5 !important;
}

.MuiAlert-root {
  border-radius: 4px !important;
  color: #131415 !important;
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
}

.MuiAlert-outlinedSuccess {
  background-color: #C8E6C9 !important;
  border: 1px solid #4CAF50 !important;
}

.MuiAlert-outlinedError {
  background-color: #FFCDD2 !important;
  border: 1px solid #C62828 !important;
}

.MuiSnackbar-anchorOriginTopCenter {
  top: 0px !important;
  width: 100% !important;
}

.zoomImageConatiner {
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

#searchSortFilter {
  .MuiInputBase-input {
    background-color: #F5F5F6 !important;
    padding-left: 1px;

    &::placeholder {
      color: #131415;
      opacity: 1;
      font-family: 'Inter';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline  {
    border-color: #125CFF !important;
  }
}