
.hr-blue {
    height: 3px;
    width: 10%;
    background-color: #3577d4;
}
.packs-container {
    margin: 0px 10% !important;
}
.pack-img {
    max-height: 406px; 
    max-width: 100%;
}
.pack-img-container-right {
    padding-left: 10% !important;
}
.pack-img-container-left {
    padding-right:  10% !important;
}

.detail-toolbar {
    padding-left: 5px !important;
    padding-right: 5px !important;
}
.oval {
    width: 28px !important;
    height: 28px !important;
    min-width: 28px !important;
    min-height: 28px !important;
    padding: 0px !important;
    border-radius: 14px !important;
    border: 1px solid #767f8f !important;
    margin-right: 10px !important;
    
    box-shadow: inset 0 0 2px 0 rgba(0, 0, 0, 0.5)  !important ;
  }
  

  .oval:hover {
    border: 2px solid #3577d4 !important;
  }

  .oval-white {
    background-color: #ffffff !important;
  }
  .oval-white-smoke {
    background-color: #fafafa !important;
  }

  .oval-red {
    background-color: #98212d !important;
  }

  .oval-blue {
    background-color: #17488a !important;
  }
  .oval-silver {
    background-color: #b9babb !important;
  }
  .oval-black {
    background-color: #000 !important;
  }

  .oval-selected {
   border: 2px solid #3577d4 !important;
   padding: 1px;
  }

  .detail-row {
      margin-top: 24px
  }
  .no-height {
      height: 0px !important;
  }
  .button-upload {

     min-width: 0 !important;
     margin-top: -60px !important; 
     padding: 15px 5px  !important;    
     
  }

  @viewport {
    zoom: 1.0;
    min-zoom: 1.0;
    max-zoom: 1.0;
  }
 